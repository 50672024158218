<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">File Uploader</span>
        </v-card-title>
        <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-file-input
                v-model="file"
                show-size
                counter
                id="file"
                required
                :loading="loading"
                label="Choose file"
                :accept="accept"
                :error-messages="formErrorFields.fileErrorMessage"
                @click="formError().remove('file', formErrorFields)"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="loading"
            @click="upload"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>

  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import crud from '@/composables/crud'
import formError from '@/composables/formErrorHandler/formError'
import snackbar from '@/components/SnackBar.vue'

export default {
  props: {
    show: Boolean,
    multiple: Boolean,
    accept: String,
    uri: String,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const dialog = ref(false)
    const file = ref(null)
    const formErrorFields = ref({
      fileErrorMessage: '',
    })

    const {
      store,
      responseMessage,
      responseMessageStatus,
      responseMessageStatusNo,
    } = crud()

    watch(() => props.show, val => {
      dialog.value = val
    })

    const closeDialog = () => {
      dialog.value = false
      emit('closeDialog')
    }

    const upload = async () => {
      const formData = new FormData()
      const data = document.getElementById('file').files[0]
      formData.append('file', data)

      await store(props.uri, loading, formData, formErrorFields)
      if (responseMessageStatusNo.value === 422) {
        formErrorFields.value.fileErrorMessage = formErrorFields.value['0ErrorMessage']
      }

      if (responseMessageStatus.value === 'success') {
        closeDialog()
      }
    }

    return {
      loading,
      formError,
      formErrorFields,
      file,
      dialog,
      closeDialog,
      responseMessage,
      responseMessageStatus,
      upload,
    }
  },

  components: {
    snackbar,
  },
}
</script>
