<template>
  <v-card class="elevation-1">
    <v-card-title>
      Adjustment Entry
    </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col
          cols="12"
          lg="4"
          md="4"
          class="mb-0 pb-0"
        >
          <!-- From Date -->
          <v-dialog
            ref="dialog"
            v-model="modalFromDate"
            :return-value.sync="fromDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                outlined
                dense
                placeholder="From Date"
                v-bind="attrs"
                :append-icon="icon.calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              scrollable
            >
              <v-btn
                text
                color="primary"
                @click="modalFromDate=false"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalFromDate=false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="4"
          class="mb-0 pb-0"
        >
          <!-- To Date -->
          <v-dialog
            ref="dialog"
            v-model="modalToDate"
            :return-value.sync="toDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                outlined
                dense
                placeholder="To Date"
                v-bind="attrs"
                :append-icon="icon.calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              scrollable
            >
              <v-btn
                text
                color="primary"
                @click="modalToDate=false"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalToDate=false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="mb-0 pb-0 text-right"
        >
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            @click="filter"
          >
            <v-icon
              small
            >
              {{ icon.filter }}
            </v-icon>
            Filter
          </v-btn>
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            @click="resetFilter"
          >
            <v-icon
              small
            >
              {{ icon.reset }}
            </v-icon>
            Reset
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.actions`]="{ item }">
        <v-icon
          v-if="item.processed_at === null"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          {{ icon.edit }}
        </v-icon>
        <v-icon
          v-if="item.processed_at === null"
          small
          @click="deleteItem(item)"
        >
          {{ icon.delete }}
        </v-icon>
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlusOutline,
  mdiCalendarOutline,
  mdiFilter,
  mdiCancel,
} from '@mdi/js'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import fnx from '@/functions/fn'

export default {
  props: ['uniqueID'],
  setup(props, { emit }) {
    const fromDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const toDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const fromDate = ref('')
    const toDate = ref('')
    const modalFromDate = ref(false)
    const modalToDate = ref(false)
    const search = ref('')
    const headers = ref([
      { text: 'Emp. No', align: 'start', value: 'employee_number' },
      { text: 'Emp. Name', value: 'employee_name' },
      { text: 'Title', value: 'title' },
      { text: 'Total Amount', value: 'total_amount' },
      { text: 'Type', value: 'type' },
      { text: 'Apply As', value: 'apply_as' },
      { text: 'Apply At', value: 'apply_at' },
      { text: 'Remarks', value: 'remarks' },
      { text: 'Action', value: 'actions' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const icon = {
      add: mdiPlusOutline,
      edit: mdiPencilOutline,
      delete: mdiDeleteOutline,
      calendar: mdiCalendarOutline,
      filter: mdiFilter,
      reset: mdiCancel,
    }
    const filterValue = ref('')

    const { remove, responseMessageStatus, responseMessage } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/payroll/adjustment-entries/masterlist`

    watch(options, () => {
      if (filterValue.value !== '') {
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      }
    })

    watch(searchTable, () => {
      if (filterValue.value !== '') {
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      }
    })

    watch(() => props.uniqueID, () => {
      if (filterValue.value !== '') {
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      }
    })

    const editItem = item => {
      emit('passData', item)
    }

    const deleteItem = async item => {
      await remove(`${process.env.VUE_APP_URI}/api/payroll/adjustment-entries/${item.id}`, loading)
      if (filterValue.value !== '') {
        await getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      }
    }

    const filter = async () => {
      if (fromDate.value !== '' && toDate.value !== '') {
        filterValue.value = fnx.base64_encode({ fromDate: fromDate.value, toDate: toDate.value })
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      } else {
        responseMessage.value = 'Date(s) are required.'
        responseMessageStatus.value = 'error'
      }
    }

    const resetFilter = () => {
      filterValue.value = ''
      toDate.value = ''
      fromDate.value = ''
      items.value = []
      totalItems.value = 0
    }

    return {
      search,
      headers,
      items,
      searchTable,
      icon,
      options,
      totalItems,
      loading,
      editItem,
      deleteItem,
      filter,
      resetFilter,
      snackbar,
      responseMessageStatus,
      responseMessage,
      fromDateValue,
      fromDate,
      modalFromDate,
      toDateValue,
      toDate,
      modalToDate,
    }
  },

  components: {
    snackbar,
  },
}
</script>
