<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
      lg="4"
      md="4"
      xl="4"
    >
      <form-view :data="fomrData" @updateUniqueID="updateUniqueID"></form-view>
    </v-col>
    <v-col
      cols="12"
      lg="8"
      md="8"
      xl="8"
    >
      <list-view @passData="passData" :uniqueID="uniqueId"></list-view>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import FormView from './form.vue'
import ListView from './list.vue'

export default {
  metaInfo: {
    title: 'Adjustment Entry',
  },

  setup() {
    const fomrData = ref({})
    const uniqueId = ref('')

    const passData = d => {
      fomrData.value = d
    }

    const updateUniqueID = () => {
      uniqueId.value = Math.random()
    }

    return {
      fomrData,
      passData,
      updateUniqueID,
      uniqueId,
    }
  },

  components: {
    ListView,
    FormView,
  },
}
</script>
