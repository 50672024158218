<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
      <v-btn
        icon
        small
        class="mt-4"
        text
        @click="formState='New'"
        title="Add New"
        v-else
      >
        <v-icon>{{icon.add}}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="mt-4"
        text
        v-if="formState===null"
        @click="openUploader=true"
        title="Upload"
      >
        <v-icon>{{icon.upload}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <!-- employee -->
        <search-employee
          :employeeId="employee"
          :employeeNumber="employeeNumber"
          :disabled="formState!=='New'"
          :errorMessage="formErrorFields.employeeErrorMessage"
          @outputId="setEmployeeId"
        ></search-employee>

        <!-- adjustment -->
        <v-autocomplete
          v-model="adjustment"
          outlined
          dense
          clearable
          label="Adjustment Title"
          item-text="title"
          item-value="id"
          :loading="adjustmentLoading"
          :items="adjustments"
          :class="formErrorFields.adjustmentErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.adjustmentErrorMessage"
          :disabled="formState===null"
          @change="formError().remove('adjustment', formErrorFields)"
        ></v-autocomplete>

        <v-row class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Amount -->
            <v-text-field
              v-model="amount"
              label="Amount"
              outlined
              dense
              type="number"
              placeholder="Amount"
              :class="formErrorFields.amountErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.amountErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('amount', formErrorFields)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- type -->
            <v-autocomplete
              v-model="type"
              :items="[
                { value: 'taxable', text: 'Taxable' },
                { value: 'nontaxable', text: 'Non Taxable' }
              ]"
              outlined
              dense
              clearable
              label="Type"
              :class="formErrorFields.typeErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.typeErrorMessage"
              :disabled="formState===null"
              @change="formError().remove('type', formErrorFields)"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Apply As -->
            <v-autocomplete
              v-model="applyAs"
              :items="[
                { value: 'payroll', text: 'Payroll' },
                { value: 'special', text: 'Special' }
              ]"
              outlined
              dense
              clearable
              label="Apply As"
              :class="formErrorFields.applyAsErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.applyAsErrorMessage"
              :disabled="formState===null"
              @change="formError().remove('applyAs', formErrorFields)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Apply to -->
            <v-dialog
              ref="dialog"
              v-model="modalApplyTo"
              :return-value.sync="applyToDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="applyTo"
                  label="Apply To"
                  outlined
                  dense
                  placeholder="Apply To"
                  v-bind="attrs"
                  v-on="on"
                  :class="formErrorFields.applyToErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.applyToErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('applyTo', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="applyTo" scrollable>
                <v-btn text color="primary" @click="modalApplyTo=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalApplyTo=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-textarea
          v-model="remarks"
          outlined
          dense
          clearable
          label="Remarks"
          class="mb-3"
          rows="3"
          :error-messages="formErrorFields.remarksErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('remarks', formErrorFields)"
        ></v-textarea>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>

    <file-uploader
      :show="openUploader"
      @closeDialog="openUploader=false"
      :accept="'.xls,.xlsx'"
      :uri="uploadUri"
    ></file-uploader>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPlusOutline, mdiUploadOutline } from '@mdi/js'
import crud from '@/composables/crud'
import get from '@/composables/get'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import FileUploader from '@/components/FileUploader.vue'
import SearchEmployee from '@/components/SearchEmployee.vue'

export default {
  props: ['data'],
  setup(props, { emit }) {
    const openUploader = ref(false)
    const loading = ref(false)
    const message = ref('')
    const icon = {
      add: mdiPlusOutline,
      upload: mdiUploadOutline,
    }
    const employee = ref(null)
    const employeeNumber = ref('')
    const adjustmentLoading = ref(false)
    const adjustment = ref('')
    const adjustments = ref([])
    const type = ref('')
    const amount = ref('')
    const applyAs = ref('')
    const applyToDate = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const applyTo = ref('')
    const remarks = ref('')
    const modalApplyTo = ref(false)
    const formState = ref(null)
    const formErrorFields = ref({
      employeeErrorMessage: '',
      typeErrorMessage: '',
      adjustmentErrorMessage: '',
      amountErrorMessage: '',
      applyAsErrorMessage: '',
      applyToErrorMessage: '',
      remarksErrorMessage: '',
    })
    const uploadUri = ref(`${process.env.VUE_APP_URI}/api/payroll/adjustment-entries/upload`)

    watch(() => props.data, () => {
      employee.value = props.data.employee_id
      employeeNumber.value = props.data.employee_number
      type.value = props.data.type
      adjustment.value = props.data.adjustment_id
      amount.value = props.data.total_amount.replace(',', '')
      applyAs.value = props.data.apply_as
      applyTo.value = props.data.apply_at
      remarks.value = props.data.remarks
      formState.value = 'Edit'
    })

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formState.value = null
      employee.value = null
      type.value = ''
      adjustment.value = ''
      amount.value = ''
      applyAs.value = ''
      applyTo.value = ''
      remarks.value = ''
    }

    const submit = async () => {
      const formData = {
        employee: employee.value,
        type: type.value,
        adjustment: adjustment.value,
        amount: amount.value,
        applyAs: applyAs.value,
        applyTo: applyTo.value,
        remarks: remarks.value,
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/adjustment-entries`
        await store(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/adjustment-entries/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        emit('updateUniqueID')
        reset()
      }
    }

    const setEmployeeId = val => {
      employee.value = val
    }

    get(`${process.env.VUE_APP_URI}/api/payroll/adjustments/descriptions`, adjustments, adjustmentLoading)

    return {
      uploadUri,
      setEmployeeId,
      employee,
      employeeNumber,
      type,
      adjustmentLoading,
      adjustment,
      adjustments,
      amount,
      remarks,
      applyAs,
      applyTo,
      modalApplyTo,
      applyToDate,
      icon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
      openUploader,
    }
  },

  components: {
    snackbar,
    FileUploader,
    SearchEmployee,
  },
}
</script>
